import businessOutline from './services/businessOutline'
import notification from './services/notification'
import user from './services/user'


const API = {
  businessOutline,
  user,
  notification,
}

export default API
