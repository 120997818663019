import React, {useCallback, useEffect, useState} from 'react'
import {BellOutlined} from '@ant-design/icons'
import {Badge, Button, Tooltip,} from 'antd'
import {AxiosResponse} from 'axios'
import NotificationDrawer from './NotificationDrawer'
import {
    INotification,
    INotificationFilter,
    INotificationsListResponse,
    INotificationSorter,
} from '../../../../config/services/notification'
import API from '../../../../config'
import NotificationPopup from './NotificationPopup'

type Props = {
    onLoad?: (
        sorter: INotificationSorter,
        filter: INotificationFilter,
        page: number,
        itemsPerPage: number,
    ) => Promise<AxiosResponse<INotificationsListResponse>>,
}

const Notification: React.FC<Props> = ({
                                           onLoad = API.notification.list,
                                       }) => {

    const [unread, setUnread] = useState<number>(0)
    const [popup, setPopup] = useState<INotification[]>([])
    const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false)

    const onLoadUnread = useCallback(() => {
        onLoad(
            {id: 'desc'},
            {
                seen: false,
            },
            1,
            300,
        )
            .then((response) => {
                setPopup(response.data.hydraMember)
                setUnread(Number(response.data.hydraTotalItems))
            })
    }, [onLoad])

    useEffect(() => {
        onLoadUnread()
        const timer = setInterval(() => {
            onLoadUnread()
        }, 35000)

        return () => clearInterval(timer)
    }, [onLoadUnread])

    return (
        <>
            <Tooltip key="notification-btn" title="Уведомления">
                <Button
                    type="text"
                    shape="circle"
                    size="large"
                    icon={
                        unread > 0
                            ? (
                                <Badge dot>
                                    <BellOutlined/>
                                </Badge>
                            )
                            : <BellOutlined/>
                    }
                    onClick={() => setDrawerOpen(true)}
                />
            </Tooltip>
            <NotificationDrawer
                key="notification-drawer"
                unread={unread}
                setUnread={setUnread}
                isOpen={isDrawerOpen}
                onClose={() => setDrawerOpen(false)}
            />
            <NotificationPopup notifications={popup}/>
        </>
    )
}

export default Notification
